import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].ws_server;
const file_server = config[config.stage].files_server;

class IconGalleryService extends IService {
  constructor(http) {
    super(http);
  }

  async upload(data) {
    try {
      const response = await this.http.post(`${file_server}/api/files/s3/upload/image`, data);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async categories() {
    try {
      const response = await this.http.get(`${server_base}/api/icon-gallery/categories`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async allIcons() {
    try {
      const response = await this.http.get(`${server_base}/api/icon-gallery`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async icons(type) {
    try {
      const response = await this.http.get(`${server_base}/api/icon-gallery/${type}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async deleteIcon(id) {
    try {
      const response = await this.http.delete(`${server_base}/api/icon-gallery`, {id});
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async deleteIcons(type) {
    try {
      const response = await this.http.delete(`${server_base}/api/icon-gallery/${type}`);
      return response.data;
    } catch (e) {
      return null;
    }
  }

  async uploadIcons(type, data) {
    try {
      const response = await this.http.post(`${server_base}/api/icon-gallery/${type}`, {icons: data});
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default IconGalleryService;