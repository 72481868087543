import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].calls_server;

class QueuesService extends IService {
    constructor(http) {
        super(http);
    }

    async create(queue) {
        try {
            const response = await this.http.post(`${server_base}/api/call/queue`, queue);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async dequeue(queueId) {
        try {
            const response = await this.http.post(`${server_base}/api/voice/dequeue/${queueId}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async list(limit = 10, skip = 0) {
        try {
            const response = await this.http.get(`${server_base}/api/call/queue?limit=${limit}&skip=${skip}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async edit(queue) {
        try {
            const response = await this.http.put(`${server_base}/api/call/queue`, queue);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async remove(queue) {
        try {
            const response = await this.http.delete(`${server_base}/api/call/queue`, {id: queue});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default QueuesService;
