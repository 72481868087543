import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].calls_server;

class HuntGroupService extends IService{
    constructor(http){
        super(http);
    }

    async create(group){
        try{
            const response = await this.http.post(`${server_base}/api/group`, group);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async list(limit, skip){
        try{
            const response = await this.http.get(`${server_base}/api/group?limit=${limit}&skip=${skip}`);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async edit(group){
        try{
            const response = await this.http.put(`${server_base}/api/group`, group);
            return response.data;
        }catch (e) {
            return null;
        }
    }

    async remove(group){
        try{
            const response = await this.http.delete(`${server_base}/api/group/${group}`);
            return response.data;
        }catch (e) {
            return null;
        }
    }
}

export default HuntGroupService;
