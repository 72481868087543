import Utils from "@/helpers/utils";

import ComboBox from "@/components/UI/ComboBox.vue";

import TwilioNumberService from "@/services/twilio.number.service";
import IconGalleryService from "@/services/icon.gallery.service";
import UserService from "@/services/user.service";
import HuntGroupService from "@/services/hunt.group.service";
import GreetingService from "@/services/greeting.service";
import QueuesService from "@/services/queues.group.service";

const service = new TwilioNumberService()
    , iconGalleryService = new IconGalleryService()
    , userService = new UserService()
    , huntGroupService = new HuntGroupService()
    , greetingService = new GreetingService()
    , queueService = new QueuesService();

export default {
  name: 'manage-number',

  components: {
    ComboBox
  },

  data() {
    return {
      phoneNumberEdit: null,

      phoneNumber: {
        Greeting: null,
        phoneNumber: '',
        friendlyName: 'Unknown',
        recordCalls: false,
        assignee: {
          type: 'NONE',
          user: null,
          huntGroup: null,
          queue: null
        },
        icon: {
          _id: null,
          Name: '',
          Type: ''
        }
      },

      editFriendlyName: false,

      modals: {
        assignNumber: false,
        assignIcon: false,
        assignGreeting: false
      },
      loading: {
        assignNumber: false,
        assignIcon: false,
        assignGreeting: false,
      },

      iconGallery: {
        categories: [],
        category: '',
        icons: [],
        icon: null
      },
      greetings: [],
      agents: [],
      groups: [],
      queues: [],

      assignOptions: [{
        value: 'USER',
        text: this.$t('agent'),
      }, {
        value: 'HUNTGROUP',
        text: this.$t('hunt_group'),
      }, {
        value: 'QUEUE',
        text: this.$t('queue'),
      }, {
        value: 'ACTION_PLAN',
        text: this.$t('action_plan'),
      }, {
        value: 'SYSTEM',
        text: this.$t('system'),
      }, {
        value: 'CAMPAIGN',
        text: this.$t('campaign'),
      }, {
        value: 'NONE',
        text: this.$t('none'),
      }],
    }
  },

  async mounted() {
    await this.getNumber();
  },

  methods: {
    async getNumber() {
      const {numberId} = this.$route.params;
      const res = await service.get(numberId);

      if (res && !res.error) {
        this.phoneNumber = res.data.number;
        this.phoneNumberEdit = JSON.parse(JSON.stringify(this.phoneNumber));
      }
    },

    async updateNumber() {
      if (
          ['ACTION_PLAN', 'CAMPAIGN', 'SYSTEM', 'NONE'].includes(this.phoneNumber.assignee.type)
      ) {
        this.phoneNumber.assignee.user = null;
        this.phoneNumber.assignee.huntGroup = null;
        this.phoneNumber.assignee.queue = null;
      }

      const res = await service.update(this.phoneNumber);
      if (res && !res.error) {
        this.$notify({type: 'success', message: this.$t('number_updated')});
      }
    },

    async assign(obj) {
      const {assignee} = this.phoneNumberEdit;

      switch (assignee.type) {
        case 'USER':
          assignee.user = obj._id;
          assignee.huntGroup = null;
          assignee.queue = null;
          break;
        case 'HUNTGROUP':
          assignee.user = null;
          assignee.huntGroup = obj._id;
          assignee.queue = null;
          break;
        case 'QUEUE':
          assignee.user = null;
          assignee.huntGroup = null;
          assignee.queue = obj._id;
          break;
        default:
          assignee.user = null;
          assignee.huntGroup = null;
          assignee.queue = null;
          break;
      }

      this.phoneNumberEdit.assignee = assignee;
    },

    async handleEditFriendlyName() {
      this.editFriendlyName = true;
    },

    async updateFriendlyName() {
      if (this.phoneNumberEdit.friendlyName) {
        this.editFriendlyName = false;

        const doEditing = this.phoneNumberEdit.friendlyName !== this.phoneNumber.friendlyName;

        if (doEditing) {
          this.phoneNumber = JSON.parse(JSON.stringify(this.phoneNumberEdit));
          await this.updateNumber();
        }
      }
    },

    async handleAssignNumber() {
      this.loading.assignNumber = true;
      await Promise.all([this.getHuntGroups(), this.getMyAgents(), this.getQueues()]);
      this.loading.assignNumber = false;
      this.modals.assignNumber = true;
    },

    async updateAssignee() {
      this.modals.assignNumber = false;
      this.phoneNumber = JSON.parse(JSON.stringify(this.phoneNumberEdit));
      await this.updateNumber();
    },

    async handleAssignIcon() {
      await this.getIconCategories();
      this.modals.assignIcon = true;
      this.iconGallery.category = this.phoneNumber?.icon?.Type || this.iconGallery.categories[0];
      this.iconGallery.icon = this.phoneNumber?.icon || null;
      await this.getIconsPerCategory(this.iconGallery.category);
    },

    async updateIcon() {
      this.modals.assignIcon = false;
      this.phoneNumber = JSON.parse(JSON.stringify(this.phoneNumberEdit));
      this.iconGallery = {categories: [], category: '', icons: [], icon: null};
      await this.updateNumber();
    },

    onIconClick(icon) {
      this.phoneNumberEdit.icon = icon;
      this.iconGallery.icon = icon;
    },

    async handleAssignGreeting() {
      this.loading.assignGreeting = true;
      await this.getGreeting();
      this.modals.assignGreeting = true;
      this.loading.assignGreeting = false;
    },

    async updateGreeting() {
      if (this.phoneNumberEdit.Greeting) {
        this.modals.assignGreeting = false;
        this.phoneNumber = JSON.parse(JSON.stringify(this.phoneNumberEdit));
        await this.updateNumber();
      }
    },


    closeModal() {
      this.phoneNumberEdit = JSON.parse(JSON.stringify(this.phoneNumber));
      this.modals.assignGreeting = false;
      this.modals.assignNumber = false;
      this.modals.assignIcon = false;
      this.editFriendlyName = false;
    },

    async getIconCategories() {
      const res = await iconGalleryService.categories();
      if (res && !res.error) {
        this.iconGallery.categories = res.data.categories;
      }
    },

    async getIconsPerCategory(category) {
      this.iconGallery.icons = [];
      const res = await iconGalleryService.icons(category);
      if (res && !res.error) {
        this.iconGallery.icons = res.data.icons;
      }
    },

    async getMyAgents() {
      const res = await userService.myAgents();
      if (res && !res.error) {
        this.agents = res.data;
      }
    },

    async getHuntGroups() {
      const res = await huntGroupService.list();
      if (res && !res.error) {
        this.groups = res.data.groups;
      }
    },

    async getQueues() {
      const res = await queueService.list(1000);
      if (res && !res.error) {
        this.queues = res.data.queues;
      }
    },

    async getGreeting() {
      const res = await greetingService.list(100000);
      if (res && !res.error) {
        this.greetings = res.data.greetings;
      }
    },
  },

  filters: {
    formatPhoneNumber(phoneNumber) {
      if (phoneNumber) {
        return Utils.formatUSNumber(phoneNumber);
      }
      return 'N/A';
    }
  },
};