import IService from './iservice';
import config from "@/config";

const server_base = config[config.stage].calls_server;

class GreetingService extends IService {
    constructor(http) {
        super(http);
    }

    async create(record) {
        try {
            let response = await this.http.post(`${server_base}/api/greeting`, record);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async update(id, record) {
        try {
            let response = await this.http.put(`${server_base}/api/greeting/${id}`, record);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async list(limit = 10, skip = 0) {
        try {
            let response = await this.http.get(`${server_base}/api/greeting?limit=${limit}&skip=${skip}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async get(id) {
        try {
            let response = await this.http.get(`${server_base}/api/greeting/${id}`);
            return response.data;
        } catch (e) {
            return null;
        }
    }

    async remove(id) {
        try {
            let response = await this.http.delete(`${server_base}/api/greeting`, {id});
            return response.data;
        } catch (e) {
            return null;
        }
    }
}

export default GreetingService;